/* CSS Document */
body {
  color: $text-color;
  font-family: $font-family-sans-serif;
  font-size: $font-size-base;
  overflow-x: hidden;
}

div.loginheader {
  background-color: $sub-heading-bg-color;
  /* margin-bottom: 30px; */

  h4{
     color: #fff;
  }
}

div.main-title-header {
  background-color: $sub-heading-bg-color;
  margin-bottom: 30px;

  h1{
    font-size:36px;
    font-weight:none;
    color:#939598;
    margin-top:10px;
  }

  h4{
     color: #fff;
  }
}


div.sub-title-header {
  background-color: $sub-heading-bg-color;
  margin-top: 0px;
  margin-bottom: 20px;

  h1{
    font-size:30px;
    font-weight:100;
    color:$brand-color;
    margin-top:10px;
  }

  h4{
     color: $text-color;
  }
}


.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}



.center {
  margin: 0 auto;
  width: 100%;
}

.just-center {
  margin: 0 auto;
}

.navbar {
  min-height: 108px !important;
  margin-bottom: 0px;
}

.navbar li a{
  color: $navbar-color !important;
}

.navbar li.active a {
  color: $brand-color !important;
  background: none !important;
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .open > a {
    background-image: linear-gradient(to bottom, #dbdbdb 0px, #e2e2e2 100%);
    background-repeat: repeat-x;
    box-shadow: none;
}


a {
  color: $text-color;
  text-decoration: none;
}

a:hover {
  color: $text-color;
}

/* navbar main */
.navbar-main, .navbar-right {
  margin-top: 40px;
}

.btn-logout{
  margin-top: -45px;
  float:right;
}

.infobanner {
  margin-top: 20px;
  margin-bottom: 20px;
}

.sitecolor {
  color: $brand-color !important;
}

hr {
  display: block;
  border: none;
  height: 10px;
  background-image: url("../images/hr-bg.png");
}

table.tableconsumer th {
  background-color: $brand-color;
  color: #fff;
  font-weight: normal;
}

table.tableconsumer {
  font-size: 14px;
  font-weight: normal;
}

img.decorethumb {
  padding-bottom: 20px;
}

@media all and (max-width: 992px) and (min-width: 768px) {
  .navbar-main {
    font-size: 11px;
  }
  img.brandimage {
    width: 100%;
  }
}

/*RELATED ARTICLES*/
.timeline {
  position: relative;
  padding: 21px 0px 10px;
  margin-top: 4px;
  margin-bottom: 30px;
}

.timeline .line {
  position: absolute;
  width: 1px;
  display: block;
  background: $brand-color;
  top: 40px;
  bottom: 65px;
  margin-left: 32px;
  color: #fbb53d;
}


.timeline a {
  color: #5D5D5D;
  text-decoration: none;
}

.timeline a:hover {
  color: #1F69B2;
}

.timeline a:hover .articleThumb img {
  box-shadow: 1px 1px 3px #666;
  -webkit-transition: .5s;
  -o-transition: .5s;
  -moz-transition: .5s;
  -transition: .5s;
  outline: 0 !important;
}

.timeline p {
  line-height: 17px;
  font-weight: 500;
  margin-bottom: 0;
}

.timeline .panel {
  position: relative;
  margin: 0 0px 25px 65px;
  clear: both;
  background-color: transparent;
  box-shadow: none;
}

.timeline .panel .articleThumb img {
  position: absolute;
  left: -64px;
  width: 60px;
  height: 60px;
  outline: 2px solid #FFF !important;
}

.timeline .panel-body {
  padding: 5px 15px;
}

.bgcolorGrey {
  background-color: #f4f4f4;
  padding: 40px 0 40px 0;
}

.bgcolorWhite {
  background-color: #fff;
}


div.sectionPrimaryHeading{

    /* border-bottom: 1px solid #a7a9ac; 
    margin-bottom: 10px;
*/
    background-color: $heading-bg-color;

    h1 {
      font-size: 32px;
      color: $heading-text-color;
    }
}

div.sectionPrimaryBox{
    padding: 20px;
    h2{
      font-size: 20px;
      text-transform: uppercase;
    }
}

.btn-primary{
  background-image: linear-gradient(to bottom,$link-color 0,$link-color 100%);
}

.btn-primary:focus, .btn-primary:hover {
    background-color: $link-color;
    background-position: 0 -15px;
}

h1.loginheading{
    font-size:36px;
    font-weight:100;
    color:#fff;
    margin-top:10px;
}

div.project-show div div{
  padding-top: 7px;
}

.site-brand-color {
  color:$brand-color; 
}

.add-margin-top{
  margin-top:15px;
}

.add-margin-bottom{
  margin-bottom:15px;
}

.heading-inline {
  display:inline;
  margin: 0;
  padding: 0;
}


.side-icon {
  font-size: 20px;
}

div.rd-pre-wrap {
  white-space: pre-wrap;
}

div.message-box{
  p {
     white-space: pre-line;
     text-align : left;
  }

}

.btn-switch {

  
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 17px;
    border-bottom-left-radius: 17px;

}


/* lgoin */

.forgot-password {
    padding-top: 0px !important;
}


img.img-box-border {

    border:2px solid #58595b;
}


.table-striped>tbody>tr:nth-of-type(odd) {
    background-color: #ebebeb;
}

div.user-in-active {
  background-color: #ff0000 !important;
}

.equal {  
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display:         flex;
}